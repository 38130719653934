let key = {};
let IPFS_IMG = "https://bidexnft.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "demo";
var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:3331/v1/admin";
  Front_market_Url = "http://localhost:3000/OLBNFT";
  var image_url = "http://localhost:3331/token";
  ImG = "http://localhost:3331";
  var tradeAddress = "0x6918fC142BD8dA882efA8280ac32DA11A2622CbE";
  var singleAddress = "0xeaaff0ab5b7f19a24746c24e8b2d6136058be3ee";
  var multipeAddress = "0x0ef500eb2a670525e6e78a6d5e72ca3b868144c2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://localhost:3331/v1";
  var chain_Id_List = [97,80001]
  var default_chainId = 97;
  // var default_rpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545/"
  var default_rpcUrl = "https://data-seed-prebsc-1-s1.bnbchain.org:8545"
  // var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  // var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  // var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  // var networkVersion = "97";
  // var chainId = "0x61";
  // BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  // var Back_Url_Token = "http://localhost:3331/v1";
}

if (EnvName === "stage") {
  Back_Url = "https://apistaging.theimpactcollective.com/admin";
  Front_market_Url = "http://localhost:3000";
  var image_url = "https://apistaging.theimpactcollective.com/token";
  ImG = "https://apistaging.theimpactcollective.com";
  var tradeAddress = "0x16D9f2EA5Ab7c93Ef3aB2c7E983CfC12FB03b91c";
  var singleAddress = "0x26a40070a000f717A32d3e6AAe9A8f26552fB662";
  var multipeAddress = "0xae4d5a74432e6e0702bc61d7e537161ff5d740ac";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "https://apistaging.theimpactcollective.com/v1";
}

if (EnvName === "demo") {
  Back_Url = "https://backend-bidex.maticz.in/v1/admin";
  Front_market_Url = "https://bidex-admin.maticz.in/";
  var image_url = "https://backend-bidex.maticz.in";
  ImG = "https://backend-bidex.maticz.in";
  var tradeAddress = "0x6918fC142BD8dA882efA8280ac32DA11A2622CbE";
  var singleAddress = "0xeaaff0ab5b7f19a24746c24e8b2d6136058be3ee";
  var multipeAddress = "0x0ef500eb2a670525e6e78a6d5e72ca3b868144c2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://192.53.121.26:5000/v1";
  var chain_Id_List = [97,80001]
  var default_chainId = 97;
  var default_rpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545/"
}

if (EnvName === "production") {
  Back_Url = "https://api.bidexnft.io/v1/admin";
  Front_market_Url = "https://bidexnft.io";
  var image_url = "https://api.bidexnft.io";
  ImG = "https://api.bidexnft.io";
  var tradeAddress = "0xD59aeE8E76Aa7c86314974d576ADe6d70Efd3090";
  var singleAddress = "0xf4bddace196c925c6f76647fa003b2865b56bfde";
  var multipeAddress = "0xae61ce2b7ff1b57a31179cf4ec5676dfb535b98a";
  var networkVersion = "56";
  var chainId = "0x56";
  BNBProvider = "https://bsc-mainnet.nodereal.io/v1/d5f3c74dd6164c8f8d7473486ebff75c";
  var Back_Url_Token = "https://api.bidexnft.io/v1";
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBProvider,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  AdminAddress: "0x025c1667471685c323808647299e5DbF9d6AdcC9",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion,
  chain_Id_List:chain_Id_List,
  default_chainId:default_chainId,
  default_rpcUrl:default_rpcUrl,
};

export default key;
