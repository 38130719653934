// const { EvmChain } = require("@moralisweb3/common-evm-utils");


export const network =
{
    "97": {   // binance testnet
    
        chain:"Binance",
        rpcUrl:"https://data-seed-prebsc-1-s1.binance.org:8545/",
        networkVersion:'97',
        chainId :'97',
        currencySymbol:"BNB",
        tokenSymbol:"CAKE",
        siteUrl:"https://testnet.bscscan.com/",
        // tokenAddress:"0x565654383Ec9F2d6184b0907cAE7AF150Ead2658",
        tokenAddress:"0x7CAB80ce0E55F46378E493B584eE61aD68878f11",
      
        singleContract :"0xbb888c8278b62a9bae4ff8d9360e8cb989a32633".toLowerCase(),
        multipleContract:"0x7f61f811ada27208f19f89df46544ca152ac95a7".toLowerCase(),
        tradeContract :("0x5fe0eaf6c7804dbe6d4e73403307e3d2fbd72c95").toLowerCase(),
        adminAddress:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
        chainName : "BSC_TESTNET",
        label : "Binance",
        value : "Binance",
    },  
    "80001": {  // polygontestnet

        chain:"Mumbai",
        rpcUrl:"https://matic-mumbai.chainstacklabs.com",
        networkVersion:'80001',
        chainId :'80001',
        currencySymbol:"MATIC",
        tokenSymbol:"CAKE",
        siteUrl:"https://mumbai.polygonscan.com",
        tokenAddress:"0xd29CA1888bAb7aB95A62A70761dfaAEEb192d874",
        
        singleContract :"0x702Acda89A18D0D1BD2c105bbC26f8730487A61a".toLowerCase(),
        multipleContract:"0xac79640345250eEdF71a9D6Bb9Fde756dDb6d677".toLowerCase(),
        tradeContract :("0x4856fdfeea5589aB7E56b06BD2424A5C3C4A202B").toLowerCase(),
        adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
        label : "Mumbai",
        value : "Mumbai",
    },
    // "43113": {  // Avalanche_Testnet 

    //     chain:"AVAX",
    //     rpcUrl:"https://api.avax-test.network/ext/bc/C/rpc",
    //     networkVersion:'43113',
    //     chainId :'43113',
    //     currencySymbol:"AVAX",
    //     tokenSymbol:"CAKE",
    //     siteUrl:"https://testnet.snowtrace.io",
    //     tokenAddress:"0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
        
    //     singleContract :"0x8317d5a7b6551a3c3d4cd331e8e4ad262057da56".toLowerCase(),
    //     multipleContract:"0x597527b3472fc02894a982cfab3427b6039c349f".toLowerCase(),
    //     tradeContract :("0x42A632626AFA32115434f157C9737c457D570678").toLowerCase(),
    //     adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
    //     chainName : "FUJI"

    // }

}